import React from 'react';

import pageStyle from '../assets/css/page.module.css';
import style from '../assets/css/main.module.css';
import Menu from '../components/menu';
import Footer from '../components/footer';
import HeroPage from '../components/hero.page';
import Hamburger from '../components/hamburger';
import ListService from '../components/list.services';
import SEO from '../components/seo';

import imgHero from '../assets/img/services/services.jpg';

import imgReferencement from '../assets/img/services/thumbnail/referencement.jpg';
import imgCopywriting from '../assets/img/services/thumbnail/copywriting.jpg';
import imgContenu from '../assets/img/services/thumbnail/contenu.jpg';
import imgWebsite from '../assets/img/services/thumbnail/website.jpg';
import imgGraphic from '../assets/img/services/thumbnail/graphicdesign.jpg';
import imgDev from '../assets/img/services/thumbnail/developpement.jpg';
import imgDevMobile from '../assets/img/services/thumbnail/dev-mobile.jpg';
import imgMaintenance from '../assets/img/services/thumbnail/maintenance.jpg';
import imgFormations from '../assets/img/services/thumbnail/formations.jpg';
import imgConseils from '../assets/img/services/thumbnail/conseils.jpg';

export default () => (
  <div id="top">
    <SEO
      title="Tous nos services | Agence digitale | MDS Digital Agency"
      description="Découvrez tous les services proposés par notre agence digitale : création de sites, copywriting, référencement, gestion de contenu, etc."
      keywords="services"
    />

    <Menu />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
      <h2>Tous nos services</h2>
      <ListService
        title="Création de sites"
        img={imgWebsite}
        link="/services/website/"
        text="Vous souhaitez créer un site web ou effectuer une refonte de votre site actuel ? Qu’il s’agisse d’un site vitrine pour votre entreprise, d’une plateforme de vente en ligne ou du développement d’un intranet/extranet, MDS vous accompagne depuis l'idée initiale de votre projet jusqu'à sa réalisation complète."
      />

      <ListService
        title="Copywriting"
        img={imgCopywriting}
        link="/services/copywriting/"
        text="Vous souhaitez créer un site web ou effectuer une refonte de votre site actuel ? Qu’il s’agisse d’un site vitrine pour votre entreprise, d’une plateforme de vente en ligne ou du développement d’un intranet/extranet, MDS vous accompagne depuis l'idée initiale de votre projet jusqu'à sa réalisation complète."
      />

      <ListService
        title="Référencement"
        img={imgReferencement}
        link="/services/referencement/"
        text="Vous souhaitez donner plus de visibilité à votre site internet ? Être présent en première page des résultats sur Google ? Augmenter le nombre de visiteurs sur votre site web ?                      Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      />
      <ListService
        title="Mise à jour de contenu"
        link="/services/mise-a-jour-contenu/"
        img={imgContenu}
        text="Vous proposez de nouveaux biens, services ? Vous souhaitez communiquer au sujet de votre actualité ? Parce que vous l’avez certainement déjà experimenté vous-mêmes sur d’autres sites web, lire des informations obsolètes sur le site d’une entreprise a pour effet de discréditer cette société aux yeux des visiteurs."
      />
      <ListService
        title="Graphic Design"
        link="/services/graphic-design/"
        img={imgGraphic}
        text="Nous sommes conscients que nous vivons dans une société d’image où la première impression peut s’avérer décisive. C’est pourquoi nous prenons le temps de cerner le prisme de l’identité de votre marque afin de vous offrir une charte graphique à la fois authentique et originale."
      />
      <ListService
        title="Développement"
        link="/services/developpement/"
        img={imgDev}
        text="Vous souhaitez ajouter des fonctionnalités à votre site?                                                  Quels que soient vos projets d’évolution pour votre site internet, nous vous conseillerons les meilleures solutions techniques et notre équipe de développeurs prendra en charge leur intégration."
      />
      <ListService
        title="Développement mobile"
        link="/services/developpement-mobile/"
        img={imgDevMobile}
        text="Parce que l’utilisation des smartphones ne cesse de croître, proposer une application mobile devient indispensable pour élargir son audience. Cette application permet à vos utilisateurs d’avoir votre outil en permanence à portée de main et de l’utiliser même hors connexion."
      />
      <ListService
        title="Maintenance"
        link="/services/maintenance/"
        img={imgMaintenance}
        text="Votre site internet doit être entretenu, vérifié et mis à jour afin de toujours fonctionner de manière optimale et sécurisée. Vous éviterez ainsi que vos visiteurs soient confrontés à des problèmes de fonctionnement du site et vous vous prémunirez des attaques de hackers."
      />
      <ListService
        title="Formations"
        link="/services/formations/"
        img={imgFormations}
        text="MDS vous propose des formations aussi bien destinées aux PME qu’aux multinationales, mais également à toute personne désireuse de se former aux métiers du web.                              Nous venons vous former dans votre entreprise ou nous vous accueillons dans nos locaux situés à Mons."
      />
      <ListService
        title="Conseils"
        img={imgConseils}
        link="/services/conseils/"
        text="Faites appel à notre équipe d’experts pour développer votre stratégie digitale. Nous définissons ensemble de nouvelles façons de rendre votre site toujours plus rentable."
      />
    </article>

    <Footer />
  </div>
);
