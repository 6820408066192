import React from 'react';

import listServiceStyle from '../components/list.services.module.css';
import 'aos/dist/aos.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

class ListService extends React.Component {
  componentDidMount() {
    const AOS = require('aos');
    this.aos = AOS;
    this.aos.init();
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    const typeSublinks = typeof this.props.sublinks;
    return (
      <AniLink
        cover
        bg="#fba515"
        to={this.props.link}
        className={listServiceStyle.no_style}
        data-aos="fade-up"
      >
        <div className={listServiceStyle.container}>
          <div
            className={listServiceStyle.img}
            style={{ backgroundImage: 'url(' + this.props.img + ')' }}
          />
          <div className={listServiceStyle.text}>
            <h3>{this.props.title}</h3>
            <p>
              {this.props.text}
              {typeSublinks !== 'undefined' && (
                <ul className={listServiceStyle.link_flex}>
                  {this.props.sublinks.map(sublink => (
                    <li>
                      <AniLink cover bg="#fba515" to={sublink[1]}>
                        {sublink[0]}
                      </AniLink>
                    </li>
                  ))}
                </ul>
              )}
            </p>
          </div>
        </div>
      </AniLink>
    );
  }
}
export default ListService;
